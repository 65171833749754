<template>
  <div
    class="sz-flex sz-items-center sz-justify-between sz-max-w-full action-item"
  >
    <div class="sz-pr-6">
      <input
        ref="input"
        v-model="value"
        v-focus
        class="sz-block sz-w-full sz-p-2 sz-border-2 sz-border-gray-200 !sz-outline-primary !sz-ring-offset-0 !sz-ring-0 !sz-outline-offset-0 !sz-ring-offset-transparent focus:!sz-border-transparent"
        :placeholder="label"
        :type="type"
        data-testid="input-onboarding-field"
        @keypress.enter="send"
      />
    </div>
    <button
      class="sz-block sz-w-12 sz-h-12 sz-rounded-md btn--primary"
      :disabled="!isValid"
      data-testid="input-onboarding-button"
      @click="send"
    >
      <SvgIcon
        name="paper-plane-light"
        class="sz-w-6 sz-h-6 sz-m-auto sz-fill-current"
      />
    </button>
  </div>
</template>

<script lang="ts">
import SvgIcon from "@/components/SvgIcon.vue";

/**
 * Default validator for the field, checks if it's a number if the field is
 * a number field, other wise if it's not empty
 */
const defaultValidator = (value: {}, type: string) => {
  if (type == "text") {
    return value !== "";
  }
  return /^\d+$/.test(value as string);
};
</script>

<script lang="ts" setup>
import { type Ref, ref, computed } from "vue";

const emit = defineEmits(["send"]);

const props = withDefaults(
  defineProps<{
    type?: string;
    label: string;
    inputValidator?: Function;
  }>(),
  {
    type: "text",
    inputValidator: defaultValidator,
  }
);

const value: Ref<string | number> = ref("");

const isValid = computed(() => {
  return props.inputValidator(value.value, props.type);
});

const send = () => {
  if (isValid.value) {
    emit("send", value.value);
  }
};
</script>
