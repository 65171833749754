<template>
  <div class="sz-flex sz-max-w-5xl message" :class="bubblePlacement">
    <div class="sz-leading-6 speech-bubble" :class="bubbleVariant">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = withDefaults(
  defineProps<{ isCounselor: boolean; isPopup: boolean }>(),
  { isPopup: false }
);

const bubbleVariant = computed(() => {
  if (props.isPopup) {
    if (props.isCounselor) {
      return "speech-bubble-popup--counseling";
    }
    return "speech-bubble-popup--client";
  }
  if (props.isCounselor) {
    return "speech-bubble--counseling";
  }
  return "speech-bubble--client";
});

const bubblePlacement = computed(() => {
  if (props.isCounselor) {
    return "sz-justify-start";
  }
  return "sz-justify-end";
});
</script>
