<template>
  <div class="conversation" :class="{ 'sz-overflow-y-scroll': !isPopup }">
    <OnboardingDialog
      v-for="dialog in dialogs"
      :key="`dialog-${dialog.id}`"
      ref="dialogRefs"
      :dialog="dialog"
    >
      <MessageBubble
        v-for="message in dialog.messages"
        :key="`messages-${message.id}`"
        ref="bubbles"
        :is-counselor="message.isCounselor"
        :is-popup="isPopup"
      >
        <MessageBubbleText :bubble-text="message.text" />
        <div
          v-if="props.showTimestamps"
          class="sz-text-xs speech-bubble_time-stamp"
        >
          {{ showDateAndTime(message.timestamp) }}
        </div>
      </MessageBubble>
    </OnboardingDialog>
  </div>
</template>

<script lang="ts" setup>
import MessageBubbleText from "./MessageBubbleText.vue";

import MessageBubble from "@/components/MessageBubble.vue";
import type IDialog from "@/interfaces/IDialog";
import OnboardingDialog from "@/components/Dialog.vue";
import { nextTick, ref, watch, type Ref } from "vue";
import { showDateAndTime } from "@/filters";

const dialogRefs: Ref<InstanceType<typeof OnboardingDialog> | null> = ref(null);
const bubbles: Ref<InstanceType<typeof MessageBubble>[] | null> = ref(null);

const props = withDefaults(
  defineProps<{
    dialogs?: IDialog[];
    showTimestamps?: boolean;
    isPopup?: boolean;
  }>(),
  {
    dialogs: () => [],
    showTimestamps: false,
    isPopup: false,
  }
);

const scrollToDialog = (dialogId: number) => {
  if (dialogRefs.value === undefined || !Array.isArray(dialogRefs.value)) {
    return;
  }

  dialogRefs.value.forEach((dialogRef) => {
    if (dialogRef.currentDialog.id === dialogId) {
      dialogRef.$el.scrollIntoView({ behavior: "smooth" });
    }
  });
};

watch(
  () => props.dialogs,
  () => {
    nextTick(() => {
      if (!bubbles.value) return;

      const length = bubbles.value.length;
      const lastBubble = bubbles.value[length - 1];
      lastBubble.$el.scrollIntoView();
    });
  },
  { deep: true, immediate: true }
);

defineExpose({ scrollToDialog });
</script>

<style lang="postcss" scoped>
.conversation {
  @apply sz-mb-4;
}
</style>
