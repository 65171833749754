<template>
  <button
    class="sz-flex sz-items-center sz-self-end sz-justify-center sz-h-12 sz-p-2 sz-space-x-3 sz-text-white tsz-transition-all sz-rounded-full sz-outline-none sz-bg-primary sz-transition-100 sz-transition-ease-in sz-text-1.5xl active:sz-outline-none focus:sz-outline-none"
    data-testid="button-onboarding-open"
    @click="toggleChat()"
  >
    <p>
      {{ t("tenantMessages.widgetCtaTitle") }}
    </p>
    <SvgIcon name="comments-solid" class="sz-w-8 sz-h-8" />
  </button>
</template>

<script lang="ts" setup>
import SvgIcon from "@/components/SvgIcon.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emit = defineEmits(["toggleChat"]);

const toggleChat = () => {
  emit("toggleChat");
};
</script>

<style lang="postcss">
.sz-bg-primary {
  color: blue;
}
</style>
