import * as Sentry from "@sentry/vue";
import type { App } from "vue";
import type { Router } from "vue-router";

export function initializeSentry(app: App, envVariables: any, router?: Router) {
  if (
    envVariables.VITE_SENTRY_DSN &&
    envVariables.VITE_ENVIRONMENT !== "review"
  ) {
    if (router) {
      const integrations = [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ];
    }

    Sentry.init({
      app,
      environment: envVariables.VITE_ENVIRONMENT,
      dsn: envVariables.VITE_SENTRY_DSN,
      release: envVariables.VITE_RELEASE_TAG,
      tracesSampleRate: 0.2,
      tracePropagationTargets: ["localhost", /api\/v1/],
      allowUrls: ["stopsmoking.ch"],
    });
  }
}

export function getSentryVitePluginConfig(
  projectName: string,
  envVariables: any,
) {
  return {
    org: "liip-qq",
    project: projectName,
    authToken: envVariables.VITE_SENTRY_AUTH_TOKEN,
  };
}
