import i18n from "./i18n";
import type ITenant from "./types/Tenant";

const filterMessages = (tenant: ITenant, lang: string) => {
  const entries: any = {};
  Object.keys(tenant.messages).forEach((key) => {
    if (key.endsWith(lang)) {
      if (tenant.messages[key]) {
        entries[`${key.slice(0, -2)}`] = tenant.messages[key];
      }
    }
  });
  return entries;
};

export const addTenantMessagesToLocale = (tenant: ITenant) => {
  tenant.availableLanguages.forEach((lang: any) => {
    const code: string = lang.iso6391Code;
    const capitalized = code.split("");

    capitalized[0] = capitalized[0].toUpperCase();

    const customTenantMessages = filterMessages(tenant, capitalized.join(""));

    const existingMessages = i18n.global.messages.value;

    i18n.global.setLocaleMessage(code, {
      ...existingMessages[code],
      tenantMessages: {
        ...customTenantMessages,
      },
    });
  });
};
