<template>
  <div>
    <div v-if="dialog.topic" class="sz-flex sz-justify-center sz-py-4">
      <span class="sz-mr-6 sz-text-lg sz-text-blue-primary">
        {{ dialogStart }}
      </span>
      <span class="sz-text-lg sz-text-center sz-text-blue-primary">
        {{ dialog.topic }}
      </span>
    </div>

    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { formatDate } from "@/filters";
import type IDialog from "@/interfaces/IDialog";
import { computed } from "vue";

const props = defineProps<{ dialog: IDialog }>();

const dialogStart = computed(() => {
  if (props.dialog.messages?.length) {
    return formatDate(props.dialog.messages[0].timestamp);
  }
  return "";
});
</script>
