<template>
  <div>
    <div ref="message" class="message">
      <MessageText :line="props.bubbleText" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import MessageText from "@/components/MessageText.vue";

const props = defineProps<{ bubbleText: string | number }>();
</script>

<style lang="postcss" scoped>
.message {
  @apply sz-whitespace-pre-line;
  word-break: break-word;
}
</style>
