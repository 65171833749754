import { inject } from "vue";

/**
 * Loads enviroment specific variables from config.json in the public folder.
 * This file is set at runtime by a k8s configmap
 */
export async function loadEnvConfig(
  envVariables: any,
  url: string = "/config.json"
): Promise<any> {
  let config;

  if (envVariables.DEV === true) {
    config = envVariables;
  } else {
    const response = await fetch(url);
    config = await response.json();
  }

  return config;
}

/**
 * Return the runtime environment variables that are injected into config in main.ts.
 * Use this if you want to access the variables in config.json
 * Returns:
 * - ImportMetaEnv: The runtime environment configuration object.
 */
export function useRuntimeEnv() {
  const runtimeEnv = inject<ImportMetaEnv>("config");
  if (!runtimeEnv) {
    throw new Error("No runtime environment found");
  }
  return runtimeEnv;
}
