import { app } from "./app";

app.directive("focus", {
  mounted: function (el: any, binding: any) {
    if (
      !Object.prototype.hasOwnProperty.call(binding, "value") ||
      binding.value
    ) {
      el.focus();
    }
  },
});
