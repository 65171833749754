import { defineStore } from "pinia";

import { Genders } from "@/types";

export type Gender = "male" | "female" | "nonbinary" | "unspecified";

interface State {
  username: string | null;
  gender: Gender | null;
  cohort: number | null;
  preferredCounselorGender: Genders | null;
}

export const useOnboardingStore = defineStore("onboarding", {
  state: (): State => ({
    username: null,
    gender: null,
    cohort: null,
    preferredCounselorGender: null,
  }),
  actions: {
    setUsername(username: string) {
      this.username = username;
    },

    setGender(gender: Gender) {
      this.gender = Genders[gender];
    },

    setCohort(cohort: number) {
      this.cohort = cohort;
    },

    setPreferredCounselorGender(gender: Gender) {
      this.preferredCounselorGender = Genders[gender];
    },

    resetState() {
      this.username = null;
      this.gender = Genders.unspecified;
      this.cohort = null;
    },
  },
});
