<template>
  <component :is="icon" ref="iconRef" :class="className"></component>
</template>

<script setup lang="ts">
import {
  type Ref,
  type DefineComponent,
  computed,
  defineAsyncComponent,
  ref,
  watch,
} from "vue";

const props = defineProps({
  /**
   * Required name of the svg file
   */
  name: {
    type: String,
    required: true,
  },
  /**
   * Optional title
   */
  title: {
    type: String,
    default: "",
  },
});

const icon = computed(() =>
  defineAsyncComponent(() => import(`../assets/icons/${props.name}.svg`))
);

const className = computed(() => `svg-icon svg-icon--${props.name}`);

const iconRef: Ref<DefineComponent | null> = ref(null);

// Watch for when icon is loaded.
watch(
  () => iconRef.value,
  (newValue) => {
    if (props.title && newValue?.$el) {
      // Prepend a title tag for accessibility.
      const titleElem = document.createElement("title");
      titleElem.innerText = props.title;
      newValue.$el.prepend(titleElem);
    }
  }
);
</script>

<!-- <style lang="postcss" scoped>
@no-prefix;
.svg-icon {
  @apply w-4 h-4;
  fill: currentColor;
}

.svg-icon--sm {
  @apply w-3 h-3;
}

.svg-icon--lg {
  @apply w-6 h-6;
}

.svg-icon--xl {
  @apply w-12 h-12;
}
@end-no-prefix;
</style> -->
