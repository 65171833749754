<template>
  <div class="sz-py-4 sz-border-t-2 sz-border-gray-200">
    <div class="sz-flex sz-flex-wrap sz-justify-end sz-text-lg popup--actions">
      <div v-for="(answer, i) in answers" :key="`answer--${i}`" class="sz-mr-6">
        <div
          v-if="answer.type !== 'input'"
          class="sz-cursor-pointer speech-bubble action-item speech-bubble-popup--client"
          @click="callFunctionAndEmit(answer.action, answer.actionPayload)"
        >
          {{ answer.text }}
        </div>
        <div v-else>
          <OnboardingActionInput
            :type="answer.inputType"
            :label="answer.label"
            :input-validator="answer.inputValidator"
            @send="send"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import OnboardingActionInput from "@/components/OnboardingActionInput.vue";
import { onMounted, onBeforeUnmount } from "vue";

const props = defineProps<{ answers: any[] }>();
const emit = defineEmits(["updateStep"]);

const callFunctionAndEmit = (action: any, payload: any) => {
  emit("updateStep", payload);

  if (!action) return;
};

const send = (value: any) => {
  emit("updateStep", value);
};

/**
 * Emit the function of the pressed number
 */
const numberPress = (event: KeyboardEvent) => {
  const key = parseInt(event.key);
  if (isNaN(key)) {
    return;
  }

  const answer = props.answers[key - 1];

  if (!answer || answer.type == "input") {
    return;
  }

  callFunctionAndEmit(answer.action, answer.actionPayload);
};

/**
 * Listen to keypress to allow to give the answers via number
 */
onMounted(() => {
  window.addEventListener("keypress", numberPress);
});

/**
 * Listen to keypress to allow to give the answers via number
 */
onBeforeUnmount(() => {
  window.removeEventListener("keypress", numberPress);
});
</script>
