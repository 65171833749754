import { app } from "./app";
import { createPinia } from "pinia";
import i18n from "./i18n";
import "./directives";
import App from "./App.ce.vue";

import { defineCustomElement } from "vue";
import { loadEnvConfig } from "@core/src/helpers/environment";
import { initializeSentry } from "@shared/src/services/sentry";
import { initializeApi } from "./api";

interface DefaultValueConfig {
  [key: string]: string | null;
}

async function initializeApp() {
  let url;
  if (import.meta.url) {
    const src = import.meta.url;
    const urlObj = new URL(src);

    // create the url to the config file with the hostname that this file was included through
    const host = urlObj.origin;
    url = host + "/config.json";

    const query = src.substring(src.indexOf("?"));
    const params = new URLSearchParams(query);
    const windowAny = window as any;

    // Define default values for various configuration options
    // These will be used if no corresponding value is provided in the URL
    const defaultValues: DefaultValueConfig = {
      floating: "true",
      buttonSelector: null, // Assuming null as default if no value is provided
      language: "de",
      color: "#0B2B69",
      secondaryColor: "#D4E0FA",
      mandant: "stopsmoking",
      disabledColor: "#cceff4",
      chatSecondaryColor: "#0B2B69",
      chatSecondaryTextColor: "#FFF",
      fontFamilySans: "Inter-Latin-Regular",
      fontFamilySerif:
        "Unit Slab OT, Georgia, Cambria, 'Times New Roman', Times, serif",
      primaryHoverColor: "#a2171c",
    };

    // Iterate through the keys of defaultValues
    // For each key, set a property on window with the value from the URL params or the default value
    Object.keys(defaultValues).forEach((key) => {
      windowAny[key] = params.get(key) || defaultValues[key];
    });

    windowAny.tenant = params.get("mandant") || "stopsmoking";

    // This is used to set the public path to the script origin
    // __webpack_public_path__ = src.match(/^(([a-z]+:)?(\/\/)?[^/]+\/).*$/)![1];
  }

  const config = await loadEnvConfig(import.meta.env, url);
  // @ts-ignore
  window.myAppConfig = config; // Store config in window object for use outside of vue context

  app.provide("config", config);

  app.use(createPinia());
  app.use(i18n);

  initializeSentry(app, config);
  initializeApi(config);

  const onboardingWidget = defineCustomElement(App);

  customElements.define("onboarding-widget", onboardingWidget);

  const element = document.createElement("onboarding-widget");
  document.body.appendChild(element);
}

initializeApp().catch((error) => {
  console.error("Failed to initialize the app:", error);
});
